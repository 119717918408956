<template>
    <div class="register1">
        <div class="form-boxs">
            <el-form :label-position="labelPosition" :model="ruleForm" ref="ruleFormss" :rules="rules" label-width="90px"
                class="demo-ruleForm" size="medium">
                <el-form-item label="性别 :" prop="sex">
                    <el-radio-group v-model="ruleForm.sex">
                        <el-radio label="男"></el-radio>
                        <el-radio label="女"></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="密码 :" prop="passwords">
                    <input type="password" style="display: none;">
                    <el-input v-model="ruleForm.passwords" show-password autocomplete="new-password"></el-input>
                </el-form-item>

                <el-form-item label="昵称 :" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="手机号码 :" prop="mobile" class="send-item">
                    <el-input v-model="ruleForm.mobile">
                        <template slot="append">
                            <div class="send-code" @click="sendCode" v-if="auth_time <= 0">
                                发送验证码
                            </div>
                            <div class="send-code re" v-else>
                                {{ `重发验证码(${auth_time}s)` }}
                            </div>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="验证码 :" prop="telCode">
                    <el-input v-model="ruleForm.telCode" maxlength="6" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="" prop="checked" style="text-align: end;">
                    <el-checkbox v-model="ruleForm.checked">{{ xieyi?.description || '查看协议' }}</el-checkbox>
                </el-form-item>
            </el-form>
            <div class="register-btn" @click="submitForm">同意并注册</div>
        </div>
        <div class="xie" v-show="xieyiOr">
            <div><img @click="close" src="../../assets/icon/nos.svg" /></div>
            <div v-html="xieyi?.content"></div>
        </div>
        <footer-com></footer-com>
    </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
    name: 'Register1',
    data() {
        const validatePass = (rule, value, callback) => {
            if (value === false) {
                callback(new Error("请勾选协议"));
            } else {
                callback();
            }
        };
        return {
            rules: {
                sex: [{ required: true, message: "请输入性别", trigger: "change" },],
                name: [{ required: true, message: "请输入公司昵称", trigger: "blur" },],
                passwords: [{ required: true, message: "请输入密码", trigger: "blur" }],
                telCode: [{ required: true, message: "请输入手机验证码", trigger: "blur" },],
                mobile: [{ required: true, message: "请输入手机号码", trigger: "blur" },],
                checked: [
                    { validator: validatePass, trigger: 'blur' }
                ],
            },//表单校验
            ruleForm: {
                passwords: "",
                name: "",
                sex: "男",
                telCode: "",
                companyid: "",
                mobile: "",
                userFrom: "闲物互联",
                checked: false
            },//表单信息
            auth_time: 0,//验证码倒计时
            labelPosition: 'left',
            xieyi: null,
            xieyiOr: false
        }
    },
    watch: {
        'ruleForm.checked'(newValue) {
            this.xieyiOr = newValue
        }
    },
    created() {
        this.getXie()
    },
    methods: {
        ...mapActions(["setInfoObj"]),
        async sendCode() {//发送手机验证码
            let flag = true;
            this.$refs.ruleFormss.validateField("mobile", (field) => {//确认有手机号及是否已发送
                if (field != "" && this.auth_time >= 0) {
                    flag = false;
                }
            });
            if (!flag) {
                return false;
            }
            axios({
                url: "https://server.bcceglobal.com/mobile/sdk/send",
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data: {
                    phone: this.ruleForm.mobile,
                    flag: 1,
                },
            }).then((res) => {
                // console.log(res);
                if (res.data.code == 200) {
                    this.$message.success("验证码已发送");
                    this.auth_time = 120;//设置倒计时秒
                    let auth_timetimer = setInterval(() => {
                        this.auth_time--;
                        if (this.auth_time <= 0) {
                            this.sendAuthCode = true;
                            clearInterval(auth_timetimer);
                        }
                    }, 1000);
                } else if (res.data.code == 10091) {
                    this.$message.warning(res.data.msg);
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch((error) => {
                console.log(error);
            });
        },

        //获取协议
        async getXie() {
            let res = await this.$api.getComList({
                categoryId: 596,
                index: 0,
                size: 4,
                type: 16,
                title: "前端注册协议"
            })
            if (res.code == 200) {
                this.xieyi = res.data.list[0]
                console.log(this.xieyi, 'res');
            } else {
                this.$message.error("协议获取失败，请刷新重试！");
            }

        },
        close() {
            this.xieyiOr = false
        },

        //提交表单
        submitForm() {
            this.$refs.ruleFormss.validate(async (valid) => {
                if (valid) {
                    //验证码是否正确
                    axios({
                        url: "https://server.bcceglobal.com/mobile/sdk/verification",
                        method: "POST",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        data: {
                            phone: this.ruleForm.mobile,
                            code: this.ruleForm.telCode,
                        },
                    }).then(async (res) => {
                        if (res.data.code == 200) {//提交注册表单
                            let form = JSON.parse(JSON.stringify(this.ruleForm));
                            if (form.sex == "男") {
                                form.sex = '1'
                            } else {
                                form.sex = '2'
                            }
                            let result = await this.$api.register1(form);
                            if (result.code == 200) {//注册完跳转登录
                                this.$alert("注册成功，请等待平台审核！", "", {
                                    showClose: false,
                                    confirmButtonText: "确定",
                                    callback: (action) => {
                                        this.onLogo(result);
                                    },
                                });
                            } else {
                                this.$message.error(result.message);
                            }
                        } else {
                            //验证码不正确及其他情况
                            this.$message.error(res.data.msg);
                        }
                    }).catch((error) => {
                        this.$message.error(error)
                        console.log(error);
                    });

                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },

        onLogo(result) {
            this.setInfoObj({//注册后信息存进vuex
                account: result.data.account,
                id: result.data.id,
                status: result.data.status,
                sex: result.data.sex,
                phone: result.data.phone,
                companyid: result.data.companyid,
                name: ''
            });
            window.localStorage.setItem("token", result.token)
            this.$router.push({ name: "MySelf" });//注册成功跳转去登录
        },
    }
}
</script>

<style lang="scss" scoped>
.register1 {
    font-size: max(14px, 1vw);

    .form-boxs {
        width: max(350px, 40vw);
        margin: 5% auto 9%;

        .register-btn {
            width: max(160px, 40%);
            padding: 2% 0%;
            margin: auto;
            background-color: #4C9C51;
            text-align: center;
            border-radius: max(20px, 3vw);
            cursor: pointer;
            user-select: none;
        }

        .send-code {
            padding: 0 10px;
            height: 100%;
            line-height: 1;
            cursor: pointer;

            //   @include flexbox(row, center, center);
            &.re {
                background-color: #f4f5f6;
                font-size: 12px;
                color: #a5a5a5;
                cursor: no-drop;
            }
        }
    }

    .xie {
        width: 80%;
        height: 90vh;
        position: fixed;
        z-index: 800;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #4C9C51 !important;
        overflow: auto;
        padding: 0% 2%;
        box-sizing: border-box;

        >div:nth-child(1) {
            text-align: end;
            padding-right: 13px;
            position: sticky;
            background-color: #4C9C51;
            z-index: 500;
            top: 0%;
            padding: 2% 0%;

            >img {
                height: max(15px, 2vw);
                width: max(15px, 2vw);
                cursor: pointer;
            }
        }
    }
}

:deep(.el-form-item__error) {
    right: 0% !important;
}
</style>